import React, { useState, FC } from 'react'

import { CloseButton } from './CloseButton'
import { Container, Content } from './TopBanner.styles'
import { StaticWebsiteData } from '../Layout/useStaticData'
import { shouldDisplay } from './utils'

/**
 * A banner that can be displayed at the top of every page on the website.
 *
 * @param {currentPageSlug} string - The slug of the current page.
 * @param {htmlContent} string - (OPTIONAL) The HTML content to display in the banner. If omitted, the banner will not be displayed.
 * @param {backgroundColour} string - The background color of the banner.
 * @param {foregroundColour} string - The foreground color of the banner.
 * @param {hideAfter} Date - (OPTIONAL) The date (in `Y-m-d H:i:s` format) after which the banner should be hidden. If omitted, the banner will always be displayed, given that the `text` field is not empty.
 * @param {hideBefore} Date - (OPTIONAL) The date (in `Y-m-d H:i:s` format) before which the banner should be hidden. If omitted, the banner will always be displayed, given that the `text` field is not empty.
 * @param {isDismissable} boolean - Whether the banner should be dismissable by the user. If present, a close button will be shown.
 * @param {blacklistedSlugs} string[] - (OPTIONAL) An array of slugs that the banner should not be displayed on. If omitted, the banner will be displayed on all pages.
 */
export const TopBanner: FC<
  StaticWebsiteData['wpgraphql']['globalOptions']['topBanner'] & {
    currentPageSlug: string
  }
> = ({
  currentPageSlug,
  htmlContent,
  backgroundColour,
  foregroundColour,
  hideAfter,
  hideBefore,
  isDismissable,
  blacklistedSlugs,
}) => {
  const [isClosed, setClosed] = useState<boolean>(false)

  const blacklistedSlugsList = blacklistedSlugs
    ? blacklistedSlugs.split(',').map((slug) => slug.trim())
    : []

  if (
    shouldDisplay({
      isClosed,
      htmlContent,
      hideAfter,
      hideBefore,
      currentDate: new Date(),
      currentPageSlug,
      blacklistedSlugs: blacklistedSlugsList,
    })
  )
    return (
      <Container id="top-banner" backgroundColour={backgroundColour}>
        <Content
          id="top-banner-content"
          dangerouslySetInnerHTML={{ __html: htmlContent! }}
          foregroundColour={foregroundColour}
        />

        {isDismissable && (
          <CloseButton
            foregroundColour={foregroundColour}
            handleClick={() => setClosed(true)}
          />
        )}
      </Container>
    )

  return null
}
